<template>
<div id="statsinstance">
  <b-field grouped group-multiline class="box" position="is-centered">
    <b-select v-model="mjpStatsIndex" @input="onMjpStatsSelect">
      <option v-for="(mjpStats, i) in mjpStatsInstances" :key="mjpStats.nid" :value="i">{{ mjpStats.name }}</option>
    </b-select>
    <b-datepicker
      placeholder="选择日期范围"
      v-model="day"
      :min-date="minDate"
      :max-date="maxDate"
      :loading="isLoading"
      icon="calendar-today"
      @input="clearRecords">
    </b-datepicker>
    <b-select v-model="ahead" v-if="isSelectToday">
      <option :value="10">10分钟</option>
      <option :value="30">30分钟</option>
      <option :value="60">1小时</option>
      <option :value="120">2小时</option>
      <option :value="300">5小时</option>
      <option :value="720">12小时</option>
      <option :value="0">全部</option>
    </b-select>
    <button class="button is-warning" :disabled="isLoading || !canUpdateRecords" @click="updateRecords">刷新数据</button>
  </b-field>
  <b-field class="box">
    <stats-instance-selector ref="instancesSelector" @change="onFilterChange" />
  </b-field>
  <div class="columns">
    <div v-for="key1 in chartKeys1" :key="key1" class="column">
      <p class="title title-4">{{ key1 }}</p>
      <line-chart :chart-data="chartDatas[key1]" :height="200"></line-chart>
    </div>
  </div>
  <div class="columns">
    <div v-for="key2 in chartKeys2" :key="key2" class="column">
      <p class="title title-4">{{ key2 }}</p>
      <line-chart :chart-data="chartDatas[key2]" :height="200"></line-chart>
    </div>
  </div>
</div>
</template>

<script>
/**
 * 显示 Audible 的大盘数据
 */
import { nextChartColor } from '@/core/util'
import LineChart from '@/components/charts/LineChart'
import StatsInstanceSelector from '@/components/stats/StatsInstanceSelector'
import { DateTime } from 'luxon'

export default {
  name: 'statsinstance',
  components: { LineChart, StatsInstanceSelector },
  created () {
    this.getMjpStatsInstance()
  },
  data () {
    const d = {
      isLoading: false,
      mjpStatsIndex: null,
      mjpStatsInstances: [],
      selectedInstanceNames: [],
      stats: {},
      minDate: new Date(2019, 9, 1),
      maxDate: new Date(),
      day: new Date(),
      chartKeys1: ['busy_percent', 'total_rps', 'total_requests'],
      chartKeys2: ['total_exceptions', 'total_harakiri', 'max_avg_rt'],
      ahead: 10
    }
    const chartDatas = {}
    for (const k1 of d.chartKeys1) {
      chartDatas[k1] = this.buildChartData()
    }
    for (const k2 of d.chartKeys2) {
      chartDatas[k2] = this.buildChartData()
    }
    d.chartDatas = chartDatas
    return d
  },
  computed: {
    canUpdateRecords () {
      return this.selectedInstanceNames.length > 0
    },
    isSelectToday () {
      const today = new Date()
      return this.day.getFullYear() === today.getFullYear() &&
        this.day.getMonth() === today.getMonth() &&
        this.day.getDate() === today.getDate()
    }
  },
  methods: {
    async onMjpStatsSelect (mjpStatsIndex) {
      const instance = this.mjpStatsInstances[mjpStatsIndex]
      this.clearRecords()
      this.isLoading = true
      try {
        const instanceData = await this.mjp.get2({
          url: '/rsi/relation/instance/bystats/',
          query: { nid: instance.nid }
        })
        this.$refs.instancesSelector.updateInstances(instanceData.servers)
      } catch (e) {
        console.error(e)
      }
      this.isLoading = false
    },
    async getMjpStatsInstance () {
      if (this.isLoading) {
        return
      }
      this.isLoading = true
      try {
        const instanceData = await this.mjp.get2({
          url: '/rsi/instance/get/all/',
          query: { r: -1, ntype: 'stats' }
        })
        this.mjpStatsInstances = instanceData.instances
        if (this.mjpStatsIndex === null && this.mjpStatsInstances.length > 0) {
          this.mjpStatsIndex = 0
          this.onMjpStatsSelect(this.mjpStatsIndex)
        }
      } catch (e) {
        console.error(e)
      }
      this.isLoading = false
    },
    async getRecords () {
      if (this.isLoading) {
        return
      }
      this.isLoading = true
      try {
        if (this.selectedInstanceNames.length === 0) {
          this.hub.alert('请先选择 mjpinstance 再刷新！', 3, 'is-danger')
        } else {
          const query = { nnames: this.selectedInstanceNames.join(','), day: this.formatDate(this.day) }
          if (this.ahead !== 0) {
            query.ahead = this.ahead
          }
          const rdata = await this.mjp.get2({
            url: this.mjpStatsInstances[this.mjpStatsIndex].deployuri + '/stats/get/',
            query,
            timeout: 60000
          })
          this.stats = rdata.stats
          const allChartData = this.parseData()
          if (allChartData !== null) {
            this.fillChartDataByKeys({ keys: this.chartKeys1, datas: allChartData })
            this.fillChartDataByKeys({ keys: this.chartKeys2, datas: allChartData })
          }
        }
      } catch (e) {
        console.error(e)
      }
      this.isLoading = false
    },
    fillChartDataByKeys ({ keys, datas }) {
      for (const k of keys) {
        if (datas) {
          this.$set(this.chartDatas, k, this.buildChartData(datas.labels.concat(), datas[k]))
        } else {
          this.$set(this.chartDatas, k, this.buildChartData())
        }
      }
    },
    ISOTime2Minute (isotime) {
      const minute = DateTime.fromISO(isotime).toFormat('HHmm')
      return Number.parseInt(minute + '1')
    },
    getAllLabels (firstStats) {
      const labelsObj = {}
      for (const minValue of firstStats) {
        // minute 的形如 2019100120381 仅到单位分。分的后面是一个数字，代表本分钟第几次出现
        let minute = this.ISOTime2Minute(minValue.createtime)
        if (labelsObj[minute] !== null) {
          labelsObj[minute] = null
        } else {
          // 如果存在相同分钟，就增加一个分钟后面的序号
          while (labelsObj[minute] === null) {
            minute++
          }
          labelsObj[minute] = null
        }
      }
      return [labelsObj, Object.keys(labelsObj)]
    },
    buildAStataItem (minValue) {
      // {
      //   busyPercent: minValue.busy_percent,
      //   totalRps: minValue.total_rps,
      //   totalRequests: minValue.total_requests,
      //   totalExceptions: minValue.total_exceptions,
      //   totalHarakiri: minValue.total_harakiri,
      //   maxAvgRt: minValue.max_avg_rt,
      // }
      const obj = {}
      for (const k1 of this.chartKeys1) {
        obj[k1] = minValue[k1]
      }
      for (const k2 of this.chartKeys2) {
        obj[k2] = minValue[k2]
      }
      return obj
    },
    initStatsByKind (statsByKind, keys) {
      for (const k of keys) {
        statsByKind[k] = []
      }
    },
    fillStatsByKind (statsByKind, keys, dataWithAllKeys, instanceName, i) {
      for (const k of keys) {
        const aInstanceStats = { data: [], label: instanceName, borderColor: nextChartColor(i) }
        // 每一项都包含 this.kv 中需要的所有值，需要将他们分开
        for (const aMinuteData of dataWithAllKeys) {
          aInstanceStats.data.push(aMinuteData[k])
        }
        statsByKind[k].push(aInstanceStats)
      }
    },
    parseData () {
      if (this.stats.length === 0) {
        return null
      }

      // 以第一个 instance 作为标准，获取 labels —— 所有的 x 轴刻度
      const firstStats = this.stats[this.selectedInstanceNames[0]]
      const [labelsObj, labelsList] = this.getAllLabels(firstStats)

      // console.log('labelsObj %o', labelsObj)

      // 数据需要重新进行组合才能在图表中使用

      // let instaneNames = Object.keys(this.stats)
      const statsByKind = { labels: labelsList }
      this.initStatsByKind(statsByKind, this.chartKeys1)
      this.initStatsByKind(statsByKind, this.chartKeys2)
      let i = 0
      for (const [instanceName, dayValue] of Object.entries(this.stats)) {
        // 复制 labelsObj ，以它的 label 键名为准来保存数据
        const aInstanceLabelAndValues = Object.assign({}, labelsObj)
        for (const minValue of dayValue) {
          // minute 的形如 2019100120381 仅到单位分。分的后面是一个数字，代表本分钟第几次出现
          let minute = this.ISOTime2Minute(minValue.createtime)
          if (aInstanceLabelAndValues[minute] === null) {
            aInstanceLabelAndValues[minute] = this.buildAStataItem(minValue)
          } else if (aInstanceLabelAndValues[minute] !== undefined) {
            // 直接丢弃 labels 中不存在的 key 的值
            // 当 minute 在 labels 中不存在的时候，labelsObj[minute] 的值为 undefined
            // 这里仅仅处理不为 undefined 的值，代表此时仅处理该 minute 设置过值的情况
            while (aInstanceLabelAndValues[minute] !== null) {
              minute++
            }
            aInstanceLabelAndValues[minute] = this.buildAStataItem(minValue)
          }
        }
        const dataWithAllKeys = Object.values(aInstanceLabelAndValues)
        this.fillStatsByKind(statsByKind, this.chartKeys1, dataWithAllKeys, instanceName, i)
        this.fillStatsByKind(statsByKind, this.chartKeys2, dataWithAllKeys, instanceName, i)
        i++
      }
      return statsByKind
    },
    clearRecords () {
      this.fillChartDataByKeys({ keys: this.chartKeys1 })
      this.fillChartDataByKeys({ keys: this.chartKeys2 })
      this.stats = {}
    },
    buildChartData (labels, datasets) {
      labels = labels || []
      datasets = datasets || []
      return { labels, datasets }
    },
    updateRecords () {
      this.clearRecords()
      this.getRecords()
    },
    onFilterChange (selectedInstanceNames) {
      this.selectedInstanceNames = selectedInstanceNames
    }
  }
}
</script>

<style>

#statsinstance {
  padding: 12px;
}
</style>
