<template>
<div id="statsinstanceselector">
  <b-field position="is-centered">
    <b-switch v-model="selectAll" @input="onSelectAll">选择全部</b-switch>&nbsp;
    <b-input v-model="filteredInstanceName" placeholder="输入名称筛选" />
  </b-field>
  <b-field grouped group-multiline position="is-centered">
    <b-checkbox v-model="selectedInstances" v-for="o in filteredInstances"
      :native-value="o.nname" :key="o.nid">
      {{ o.nname }}
    </b-checkbox>
  </b-field>
</div>
</template>

<script>
/**
 mjpstats instance 选择器，以 CheckBox 的方式展现
 */
export default {
  name: 'statsinstanceselector',
  data () {
    return {
      selectAll: false,
      // 所有实例
      instances: [],
      // 待筛选的名称
      filteredInstanceName: null,
      selectedInstances: []
    }
  },
  computed: {
    filteredInstances () {
      if (!this.filteredInstanceName) {
        return this.instances.concat()
      }
      if (this.instances.length > 0) {
        return this.instances.filter(item => item.nname.includes(this.filteredInstanceName))
      }
      return []
    }
  },
  methods: {
    updateInstances (instances) {
      // this.selectedInstances = instances.map(item => item.nname)
      // 因为网络传输量的原因，默认不选择任何 instance
      this.selectedInstances = []
      this.instances = instances
      this.selectAll = false
      this.filteredInstanceName = null
    },
    onSelectAll (select) {
      if (select) {
        this.filteredInstances.forEach(item => {
          if (!this.selectedInstances.includes(item.nname)) {
            this.selectedInstances.push(item.nname)
          }
        })
      } else {
        this.selectedInstances.splice(0, this.selectedInstances.length)
      }
      this.$emit('change', this.selectedInstances)
    }
  },
  watch: {
    selectedInstances (to, from) {
      this.$emit('change', this.selectedInstances)
    }
  }
}
</script>
